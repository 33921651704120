export type Ogtag = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
};

export enum imgCategory {
  USER_IMG = "userImg", // 유저들 프로필사진 이미지
  COMPANY_IMG = "companyImg", // 회사이미지
  PROJECT_IMG = "projectImg", // 프로젝트 이미지
  MINUTES_IMG = "minutesImg", // 회의록 이미지
  PERFORMANCE_IMG = "performanceImg", // 성과관리 이미지
  PRE_PITCHING_IMG = "prepitchingImg", // 예비투심 이미지
  EVALUATION_IMG = "evaluationImg", // 투자심사 이미지

  EVENT = "event", // 홈페이지 이벤트
  BANNER = "banner", // 홈페이지 배너
  POPUP = "popup",
  ANNOUNCEMENT = "announcement", // 공고
}

export enum ContentCategory {
  REPORT = "Report",
  COLUMN = "Column",
  INTERVIEW = "Interview",
  REVIEW = "Review",
}
export enum NewsCategory {
  BLUEPOINT = "Bluepoint",
  BLUE_FAMILY = "BlueFamily",
}

export enum NavigationMenu {
  MAIN = "Main",
  ABOUT = "About",
  COMPANY = "Company",
  PROGRAM = "Program",
  STARTINGPOINT = "StartingPoint",
  ALUMNI = "Alumni",
  BLUELABS = "Bluelabs",
  BLUESCHOOL = "Blueschool",
  OPENINNOVATION = "OpenInnovation",
  TEAM = "Team",
  TEAMDETAIL = "TeamDetail",
  PORTFOLIO = "Portfolio",
  PORTFOLIODETAIL = "PortfolioDetail",
  INSIGHT = "Insight",
  EVENTS = "Events",
  EVENTSDETAIL = "EventsDetail",
  CONTENTS = "Contents",
  CONTENTSDETAIL = "ContentsDetail",
  NEWS = "News",
  BLUELETTER = "Blueletter",
  CONTACT = "Contact",
  APPLY = "Apply",
  PARTNERSHIP = "Partnership",
  FAQ = "FAQ",
  PRIVACY_POLICY = "privacy-policy",
  IR = "Ir",
  DISCLOSURE_INFOMATION = "DisclosureInfomation",
  STOCK_INFOMATIOIN = "StockInfomation",
  IR_DATA = "IrData",
  ANNOUNCEMENT = "Announcement",
  ANNOUNCEMENTDETAIL = "AnnouncementDetail",
}

export enum ContactMenu {
  APPLY = "Apply",
  PARTNERSHIP = "Partnership",
  FAQ = "Faq",
}

export enum InsightMenu {
  ALL = "all",
  EVENTS = "events",
  CONTENTS = "contents",
  NEWS = "news",
  BLUELETTER = "blueletter",
}

export enum IRMenu {
  DISCLOSURE_INFOMATION = "disclosure_infomation",
  STOCK_INFOMATIOIN = "stock_infomation",
  IR_DATA = "ir_data",
  ANNOUNCEMENT = "Announcement",
}

export type Mode = "Desktop" | "Tablet" | "Mobile";

export enum IRArchiveMenu {
  EARNING_RELEASE = "earningRelease",
  AUDIT_REPORT = "auditReport",
  BUSINESS_REPORT = "businessReport",
  ANNUAL_REPORT = "annualReport",
}

export interface Category {
  id: number;
  name: string;
}
export const allCategory: Category[] = [
  { id: 1, name: ContentCategory.REPORT },
  { id: 2, name: ContentCategory.COLUMN },
  { id: 3, name: ContentCategory.INTERVIEW },
  { id: 4, name: ContentCategory.REVIEW },
];

export const allNewsCategory: Category[] = [
  { id: 1, name: NewsCategory.BLUEPOINT },
  { id: 2, name: NewsCategory.BLUE_FAMILY },
];

export const irArchiveType: any[] = [
  { title: "실적발표", type: "earningRelease" },
  { title: "감사보고서", type: "auditReport" },
  { title: "사업보고서", type: "businessReport" },
  { title: "연차보고서", type: "annualReport" },
];

export interface Stage {
  id: number;
  name: string;
}
export const allStage: Stage[] = [
  { id: 1, name: "Seed" },
  { id: 2, name: "Venture & Growth" },
  { id: 3, name: "Alumni" },
];
export const stage: SelectData<Stage>[] = [
  {
    id: 1,
    text: allStage[0].name,
    data: allStage[0],
  },
  {
    id: 2,
    text: allStage[1].name,
    data: allStage[1],
  },
  {
    id: 3,
    text: allStage[2].name,
    data: allStage[2],
  },
];
export interface FileType {
  key: string;
  fileName: string;
  fileSize: number;
  fileType: string;
}

export interface UserInfo {
  isNew?: boolean;
  isChange?: boolean;
  isDelete?: boolean;
  hpUserId: number | string;
  acUserId?: number | string;
  email?: string;
  name?: string;
  profileImgUrl?: string;
  profileImgKey?: string;
  hpPortfolio?: PortfolioProps[];
  hpUserMajor?: { hpIndustrialTech: IndustryTech }[];
  introduction?: string;
  hpStructure?: Structure;
  hpPosition?: Position;
  twitter?: string;
  facebook?: string;
  blog?: string;
  instagram?: string;
  careerAndEducation?: {
    education?: Education[];
    career?: Career[];
  };
  dateOfEntry?: string;
  keyword?: string[] | null;
  acUserInfo?: {
    acPosition?: acPosition;
    acResponsibility?: acResponsibility;
    acStructure?: acStructure;
  };
}

interface acPosition {
  name: string;
}

interface acResponsibility {
  name: string;
}

interface acStructure {
  department?: string;
  group?: string;
  team?: string;
}
export interface Education {
  schoolName?: string;
  major?: string;
}

export interface Career {
  company?: string;
  position?: string;
}

export interface Structure {
  hpStructureId?: number | string;
  name?: string;
  structureIndex?: number;
  introduction?: string;
  parent?: number | string;
}

export interface IndustryTech {
  acIndustrialTechId: number;
  name: string;
}

export interface Position {
  hpPositionId: number | string;
  name: string;
}

export interface SelectData<T = unknown> {
  id: number | string;
  text: string;
  data?: T;
}

export interface LatticeUserInfo {
  acUserId: number;
  email: string;
  name: string;
  profileImgKey?: string;
  profileImgUrl?: string;
  acUserMajor: {
    acUserMajorId: number;
    acIndustrialTech: IndustryTech;
  }[];
  employmentStatus: {
    employmentStatusId: number;
    name: string;
  };
}

export interface PortfolioProps {
  active: boolean;
  portfolioId: number;
  companyName: string;
  description: string;
  companyImgKey: string;
  companyImgUrl: string;
  introduction: string;
  stage: string;
  hpPfIndustrialTech: {
    hpIndustrialTech: IndustryTech;
    hpPfIndustrialTechId: number;
  }[];
  homepageRegisterDate: string;
  preLatticePortfolioUpdatedAt: string;
  curLatticePortfolioUpdatedAt: string;
}

export interface LatticePortfolioInfoType {
  [key: string]:
    | string
    | number
    | undefined
    | null
    | IndustryTech[]
    | CompanyProjectType[];

  portfolioId: number;
  companyName: string;
  addr1: string;
  addr2: string;
  addr3: string;
  addrDetail?: string;
  ceoName: string;
  companyImgKey?: string | null;
  companyImgUrl?: string | null;
  description: string;
  introduction?: string;
  establishmentDay: string;
  homepageUrl?: string;
  pfIndustrialTech: IndustryTech[];
  pfProject: CompanyProjectType[];
}

export interface HomepagePortfolioInfoType {
  [key: string]:
    | string
    | number
    | undefined
    | null
    | IndustryTech[]
    | CompanyProjectType[]
    | HpIndustryTech[];

  portfolioId: number;
  companyName: string;
  addr1: string;
  addr2: string;
  addr3: string;
  addrDetail?: string;
  stage: string;
  ceoName: string;
  companyImgKey?: string | null;
  companyImgUrl?: string | null;
  description: string;
  introduction?: string;
  establishmentDay: string;
  homepageUrl?: string | null;
  hpPfIndustrialTech: HpIndustryTech[];
  hpPfProject: CompanyProjectType[];
}

export interface HpIndustryTech {
  hpIndustrialTech: IndustryTech;
  hpPfIndustrialTechId: number;
}

export interface CompanyProjectType {
  pfProjectId: number | string;
  summary?: string;
  description?: string;
  projectImgKey?: string | null;
  projectImgUrl?: string | null;
}
export interface Address {
  addr1?: string;
  addr2?: string;
  addr3?: string;
  fullAddr?: string;
  addrDetail?: string;
}

export interface BoardDataProps {
  name: string;
  position?: string[];
  assignedTask?: string[];
  profile?: string[];
  appointmentDate?: string[];
  term?: string[];
}

export interface IRDataProps {
  id: number;
  type: string;
  year: number;
  quarterNum: string;
  title: string;
  fileName: string;
  fileKey: string;
}

export const boardData: BoardDataProps[] = [
  {
    name: "이용관",
    position: ["대표이사"],
    assignedTask: ["대표이사", "경영위원회"],
    profile: [
      "㈜플라즈마트 대표(00.04~12.08)",
      "㈜플라즈마트General Manager(12.09~14.04)",
      "블루포인트파트너스 대표(14.07~현재)",
    ],
    appointmentDate: ["2017.03.27(취임)", "2020.03.28(중임)"],
    term: ["2023 정기주주총회"],
  },
  {
    name: "김용건",
    position: ["부대표이사"],
    assignedTask: ["경영위원회"],
    profile: [
      "삼성전자㈜ 대리(91.03~97.11)",
      "삼성자동차㈜ 대리(97.11~99.06)",
      "미래산업㈜ 차장(99.07~02.02)",
      "바이옵트로㈜ 부장(02.03~08.03)",
      "㈜플라즈마트 이사(08.04~14.09)",
      "블루포인트파트너스 부대표(14.10~현재)",
    ],
    appointmentDate: ["2017.03.27(취임)", "2020.03.28(중임)"],
    term: ["2023 정기주주총회"],
  },
  {
    name: "황희철",
    position: ["본부장"],
    assignedTask: ["경영위원회"],
    profile: [
      "㈜마이크로에어봇 대표(03.06~10.09)",
      "㈜한화 책임(10.10~16.06)",
      "블루포인트파트너스 이사(16.06~현재)",
    ],
    appointmentDate: ["2019.04.30(취임)", "2022.04.30(중임)"],
    term: ["2025 정기주주총회"],
  },
  {
    name: "차병곤",
    position: ["CFO"],
    assignedTask: ["경영위원회"],
    profile: [
      "삼일회계법인 감사본부(11.09~15.06)",
      "삼일회계법인Deal본부(15.07~18.08)",
      "블루포인트파트너스 이사(18.08~현재)",
    ],
    appointmentDate: ["2018.12.19(취임)", "2021.12.19(중임)"],
    term: ["2024 정기주주총회"],
  },
  {
    name: "임성빈",
    position: ["사외이사"],
    assignedTask: [],
    profile: [
      "UNIST 경영공학부 조교수(20.01~20.08)",
      "UNIST 인공지능대학원 조교수(20.08~ 현재)",
      "블루포인트파트너스 사외이사(21.01~ 현재)",
    ],
    appointmentDate: ["2021.01.01"],
    term: ["2024 정기주주총회"],
  },
  {
    name: "곽홍일",
    position: ["감사"],
    assignedTask: [],
    profile: [
      "동국산업㈜(08.04~10.06)",
      "대주회계법인(11.09~13.06)",
      "삼일회계법인(13.07~16.09)",
      "대주회계법인(16.10~19.10)",
      "블루포인트파트너스 감사(19.03~현재)",
      "이촌회계법인(20.01~현재)",
    ],
    appointmentDate: ["2022.04.25"],
    term: ["2025 정기주주총회"],
  },
  {
    name: "강동석",
    position: ["기타비상무이사"],
    assignedTask: [],
    profile: [
      "KTB Networks 심사역(98.01~00.02)",
      "TechnologyVentures 심사부장(00.02~00.06)",
      "소프트뱅크벤처스 선임심사역(00.06~01.06)",
      "Media2.0 재무이사(01.06~02.12)",
      "다음커뮤니케이션스 CEO Staff(03.01~03.02)",
      "소프트뱅크벤처스 부사장(03.02~현재)",
    ],
    appointmentDate: ["2020.03.27"],
    term: ["2023 정기주주총회"],
  },
  {
    name: "박덕규",
    position: ["기타비상무이사"],
    assignedTask: [],
    profile: ["현대모비스(10~14)", "KB인베스트먼트 벤처투자본부이사(14~현재)"],
    appointmentDate: ["2020.03.27"],
    term: ["2023 정기주주총회"],
  },
];
