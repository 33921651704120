import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import axios from "axios";
import { imgCategory } from "admin/type/type";
import imageCompression from "browser-image-compression";
import * as api from "../../../api/api";
import { ReactComponent as Remove } from "../../../assets/edit_cancel.svg";

interface Props {
  editable: boolean;
  onChangeFile: (url: string | undefined, key: string | undefined) => void;
  onChangeStart?: () => void;
  onChangeEnd?: () => void;
  width?: number;
  height?: number;
  imgUrl?: string | null;
  category: imgCategory;
  maxSizeMB?: number;
}
const UploadImg: React.FC<Props> = (props) => {
  const [hoverShow, setHoverShow] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(true);
  const reRender = () => setReload((prevState) => !prevState);
  const inputRef = useRef<HTMLInputElement>(null);

  const resetFile = () => {
    if (!props.editable) return;

    if (inputRef.current) inputRef.current.value = "";
    if (props.onChangeFile) props.onChangeFile(undefined, undefined);
  };

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) return;

      if (props.onChangeStart) props.onChangeStart();

      const compressedFile = await imageCompression(e.target.files[0], {
        maxSizeMB: props.maxSizeMB || 0.2, // 허용하는 최대 사이즈 지정
        maxWidthOrHeight: 1920, // 허용하는 최대 width, height 값 지정
        useWebWorker: true, // webworker 사용 여부})
        onProgress: (progress) => {
          if (progress >= 100) {
            if (props.onChangeEnd) props.onChangeEnd();
          }
        },
      });

      const formData = new FormData();
      formData.append("file", compressedFile);
      formData.append("category", props.category);

      const result = (await axios.post(api.uploadAndGetUrl(), formData)).data;
      if (result.length > 0) {
        if (props.onChangeFile) {
          props.onChangeFile(result[0].url, result[0].key);
        }

        e.target.value = "";
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container
      width={props.width || 216}
      height={props.height || 216}
      onMouseOver={() => setHoverShow(true)}
      onMouseLeave={() => setHoverShow(false)}
    >
      {props.imgUrl ? (
        <img className="logo" src={props.imgUrl} alt="img" />
      ) : (
        <EmptyImage
          onClick={() => {
            if (props.editable && inputRef.current) inputRef.current.click();
          }}
        >
          <span className="font-medium-14" style={{ color: "var(--grey_50)" }}>
            눌러서 이미지 등록하기
          </span>
          <span className="font-regular-12" style={{ color: "var(--grey_20)" }}>
            최대파일 크기 3MB, 파일형식 img
          </span>
        </EmptyImage>
      )}
      {props.editable && props.imgUrl && hoverShow && (
        <>
          <div
            className="overlay font-bold-14"
            onClick={() => {
              if (!props.editable) return;
              if (!inputRef.current) return;

              inputRef.current.value = "";
              inputRef.current.click();
            }}
          >
            눌러서 변경하기
          </div>
          <Remove className="remove" onClick={resetFile} />
        </>
      )}
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onChangeFile}
      />
    </Container>
  );
};

const Container = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  min-height: ${(props) => props.height}px;
  max-height: ${(props) => props.height}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--white);
  border: 1px solid var(--grey_20);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  input {
    display: none;
  }

  .logo {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;

    object-fit: contain;
  }

  .overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--white);
    cursor: pointer;
  }
  .remove {
    position: absolute;
    display: flex;
    width: 16px;
    height: 16px;
    top: 10px;
    right: 10px;

    cursor: pointer;
  }
`;
const EmptyImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 4px;
  color: var(--black1);
  cursor: pointer;
`;
export default UploadImg;
